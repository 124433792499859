import clsx from "clsx";
import Image from "../../image/image";
import Button from "../../button/button";

import "./briefCard.css";

function BriefCard({
  image,
  label,
  time,
  text,
  href,
  aos,
  aosDuration,
  className,
  ...props
}) {
  return (
    <div className={clsx("brief-card", className)} {...props}>
      <Image className="brief-card-image" src={image} />
      <div className="brief-card-text-container">
        <div className="brief-card-label-and-text">
          <span className="brief-card-label">{label}</span>
          <span className="brief-card-text">{text}</span>
        </div>
        <span className="brief-card-time">{time}</span>
      </div>
      <Button
        className="general-btn brief-card-btn"
        href={href}
        textBtn="Xem ngay"
        isOpenOtherTab={true}
      />
    </div>
  );
}

export default BriefCard;
