import clsx from "clsx";
import Image from "../image/image";
import Button from "../button/button";
import NavBar from "../navBar/navBar";
import MobileMenu from "../mobileMenu/mobileMenu";
import VNFlag from "~/assets/images/vn-flag.png";
import SmallOwl from "~/assets/images/small-owl.png";
import ViniText from "~/assets/images/vini-text.png";

import "./header.css";

function Header({ isCostAndPolicy, setTitle, navBarTitle, className }) {
  return (
    <div className={clsx("general-padding header", className)}>
      <Button className="header-home-btn" to="/">
        <Image src={ViniText} alt="Vini Text" />
      </Button>
      <NavBar
        className="header-nav-bar"
        isCostAndPolicy={isCostAndPolicy}
        setTitle={setTitle}
        navBarTitle={navBarTitle}
      />
      <div className="header-right-side">
        <Button className="header-flag" to="#">
          <Image src={VNFlag} alt="VN Flag" />
        </Button>
        <div className="header-phone">
          <Button
            className="general-btn header-btn"
            textBtn="0707 87 26 38"
            href="tel:0707872638"
          />
          <Image className="header-phone-img" src={SmallOwl} alt="Small Owl" />
        </div>
      </div>
      <MobileMenu isCostAndPolicy={isCostAndPolicy} />
    </div>
  );
}

export default Header;
