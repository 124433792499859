import clsx from "clsx";
import TransparentButton from "~/components/transparentButton/transparentButton";
import Category from "~/components/category/category";
import CardSection from "~/components/section/cardSection/cardSection";

import "./briefProjectsList.css";

function BriefProjectsList({
  title,
  text,
  categories = [],
  cards = [],
  href,
  className,
}) {
  return (
    <div className={clsx("brief-projects-list", className)}>
      <div className="brief-projects-list-title-container">
        <span
          className="brief-projects-list-text brief-projects-list-title"
          data-aos="fade-right"
        >
          {title}
        </span>
        <span className="brief-projects-list-text" data-aos="fade-left">
          {text}
        </span>
      </div>
      <Category
        className="brief-projects-list-category"
        categories={categories}
      />
      <CardSection cards={cards} />
      <TransparentButton
        className="brief-projects-list-btn"
        textBtn="Xem thêm"
        to={href}
      />
    </div>
  );
}

export default BriefProjectsList;
