import MailIcon from "~/assets/icons/mailIcon";
import LocationIcon from "~/assets/icons/locationIcon";

const categories = [
  {
    title: "Về Vinipr Media",
    items: [
      "Giới thiệu về Vini",
      "Tuyển dụng",
      "Liên hệ",
      "Blog",
      "Bản tin Vini",
    ],
  },
  {
    title: "Giải pháp Website",
    items: [
      "Thiết kế Website cơ bản",
      "Thiết kế Website nâng cao",
      "Thiết kế Website chuyên nghiệp",
      "Quản trị Website",
      "Content Marketing cho website",
      "Mẫu Website",
    ],
  },
  {
    title: "Giải pháp App/Ứng dụng",
    items: [
      "Thiết kế App/ứng dụng cơ bản",
      "Thiết kế App/ứng dụng nâng cao",
      "Thiết kế App/ứng dụng chuyên nghiệp",
      "Đăng ký với Bộ công thương",
      "Content Marketing cho App",
    ],
  },
  {
    title: "Giải pháp Marketing Online",
    items: [
      "Dịch vụ SEO",
      "Dịch vụ Social Media",
      "Thiết kế Landing Page",
      "Tư vấn xây dựng chiến lược",
      "Content Marketing",
    ],
  },
  {
    title: "Giải pháp ĐẶC BIỆT",
    items: [
      "Growth Hacking - Đặc biệt",
      "Growth Marketing - Đặc biệt",
      "Chiến lược truyền thông nội bộ",
      "Chiến lược xây dựng Nhân hiệu",
    ],
  },
];

const infos = [
  {
    title: "Email",
    text: "info@vinipr.vn",
    href: "mailto:info@vinipr.vn",
    icon: MailIcon,
  },
  {
    title: "Địa chỉ",
    text: "L17-11, Tầng 17, Tòa nhà Vincom Center, 72 Lê Thánh Tôn, P. Bến Nghé, Q.1, TP. HCM.",
    icon: LocationIcon,
  },
];

export { categories, infos };
