import Button from "~/components/button/button";

import "./categorySection.css";

function CategorySection({ title, categories = [], className }) {
  return (
    <div className={className}>
      <div className="general-gradient-title">{title}</div>
      {categories.map((category, index) => (
        <Button className="footer-item" textBtn={category} key={index} />
      ))}
    </div>
  );
}

export default CategorySection;
