import clsx from "clsx";

import "./partTwo.css";

function PartTwo({ className }) {
  return (
    <div className={clsx("footer-section general-padding part-two", className)}>
      <div className="footer-section-text">
        <span className="general-gradient-title">
          THAM GIA ĐỒNG HÀNH CÙNG VINIPR
        </span>
      </div>
      <span>
        Vinipr luôn sẽ đảm bảo cung cấp cho bạn những sản phẩm/dịch vụ chất
        lượng ưng ý. Bên cạnh đó, Vinipr cũng có sự tin tưởng hợp tác của các
        hương hiệu đối tác tốt nhất ở đa dạng nhiều lĩnh vực khác nhau. Phạm vi
        xây dựng của Vinipr bao gồm sự trải nghiệm thực tế của người dùng đối
        với sản phẩm/dịch vụ hay một điều kỳ thú thực tế trải qua và sự phát
        triển của Việt Nam và Thế giới, bao gồm kinh tế, kinh doanh, quản lý,
        hàng hóa, lưu thông, sự kiện, nhân vật, tiêu điểm và những vấn đề để
        phát triển bền vững.
      </span>
    </div>
  );
}

export default PartTwo;
