import clsx from "clsx";

import "./partOne.css";

function PartOne({ className }) {
  return (
    <div className={clsx("footer-section general-padding part-one", className)}>
      <div className="footer-section-text">
        <span className="general-gradient-title">Trụ sở chính:</span> L17-11,
        Tầng 17, Tòa nhà Vincom Center, 72 Lê Thánh Tôn, P. Bến Nghé, Q.1, TP.
        HCM.
      </div>
      <div className="footer-section-text">
        <span className="general-title">Vinipr Media</span> nhận thông tin trực
        tuyến và hỗ trợ tận tình, nhận phản hồi, đánh giá và bàn giao sản phẩm
        hoàn thiện trực tiếp tại văn phòng hoặc tại nơi làm việc của khách hàng.
      </div>
      <div className="footer-section-text">
        Giấy chứng nhận Đăng ký Kinh doanh số{" "}
        <span className="general-title">0317020213</span> do Sở Kế hoạch và Đầu
        tư Thành phố Hồ Chí Minh cấp lần đầu ngày 10/11/2021. © 2023 - Bản
        quyền của Công ty TNHH Vinipr Media.
      </div>
    </div>
  );
}

export default PartOne;
