import { useEffect } from "react";
import Page from "../page";
import Header from "~/components/header/header";
import Footer from "~/components/footer/footer";
import ScrollToTopButton from "~/components/scrollToTopButton/scrollToTopButton";
import FAQs from "./components/faq/faq";
import Subscribe from "./components/subscribe/subscribe";
import HeaderContent from "~/components/headerContent/headerContent";
import BriefProjectsList from "./components/briefProjectsList/briefProjectsList";
import MultiCards from "~/assets/images/multi-cards.png";
import { infoNumbers, projectsList } from "./index";
import { setDocumentTitle } from "~/utils/common";

function HomePage() {
  useEffect(() => {
    setDocumentTitle("Trang chủ");
  }, []);

  return (
    <Page className="home">
      <div className="general-content">
        <HeaderContent
          texts={["Giải pháp Marketing & Công nghệ,", "tăng trưởng..."]}
          gradientText="Thời cơ"
          subtext="Lấy tinh thần lập nghiệp của người Á Đông để làm cảm hứng cho mọi sự phát triển và sáng tạo. Vinipr Agency luôn học hỏi nâng cao năng lực mỗi ngày để tiến tới sự hội nhập toàn cầu!"
          infoNumbers={infoNumbers}
          image={MultiCards}
        />
        <ul className="general-list">
          {projectsList.map((project, index) => (
            <BriefProjectsList
              title={project.title}
              text={project.text}
              categories={project.categories}
              cards={project.cards}
              href={project.href}
              key={index}
            />
          ))}
        </ul>
      </div>
      <FAQs />
      <Subscribe />
    </Page>
  );
}

export default HomePage;
