import { routesPath } from "~/constants/routesPath";

const navBar = [
  {
    title: "Giới thiệu",
    href: routesPath[1],
  },
  {
    title: "Bảng giá",
    href: routesPath[2],
  },
  {
    title: "Hoạt động",
    href: routesPath[3],
  },
  {
    title: "Blog",
    href: routesPath[4],
  },
  {
    title: "Liên hệ",
    href: routesPath[5],
  },
];

const costAndPolicy = [
  {
    title: "Bảng giá",
  },
  {
    title: "Trả góp",
  },
  {
    title: "Chính sách",
  },
];

export { navBar, costAndPolicy };
