import Asifarm from "~/assets/images/asifarm.png";
import Asisell from "~/assets/images/asisell.png";
import Thinkerum from "~/assets/images/thinkerum.png";
import DurationWorld from "~/assets/images/duration-world.png";

const infoNumbers = [
  {
    label: "Công nghệ",
    number: 157,
  },
  {
    label: "Marketing",
    number: 200,
  },
  {
    label: "Khách hàng",
    number: 500,
  },
];

const projectsList = [
  {
    title: "Các dự án Công nghệ",
    text: "Ở ViNi thì mọi dự án đều được chúng tôi khảo sát, thực hiện theo đúng mục tiêu kinh doanh của khách hàng. Chúng tôi trân trọng mọi ý tưởng của mọi người!",
    href: "/web",
    categories: [
      "Công nghệ",
      "Nghệ thuật",
      "Trò chơi",
      "Thể thao",
      "Thương mại",
      "Dịch vụ",
      "Du lịch",
      "Giáo dục",
      "Nông nghiệp",
    ],
    cards: [
      {
        image: Asifarm,
        label: "Farm",
        text: "AsiFarm",
        time: "01/01/2024",
        href: "https://asifarm.vercel.app/",
      },
      {
        image: DurationWorld,
        label: "Sale",
        text: "Duration World",
        time: "01/01/2024",
        href: "https://durian-world.vercel.app/",
      },
      {
        image: Asisell,
        label: "Sale",
        text: "Asisell",
        time: "01/01/2024",
        href: "https://asisell.vercel.app/",
      },
      {
        image: Thinkerum,
        label: "Blog",
        text: "Thinkerum",
        time: "01/01/2024",
        href: "https://thinkerum.vercel.app/",
      },
      {
        image: Asisell,
        label: "Admin",
        text: "Admin Asisell",
        time: "01/01/2024",
        href: "https://admin-asisell.vercel.app/",
      },
    ],
  },
  {
    title: "Các dự án Ứng dụng",
    text: "Tôn trọng tính độc đáo của ý tưởng, các sản phẩm của ViNi đều được xây dựng theo ý tưởng và mục tiêu tuyệt vời của khách hàng. Đừng ngại thể hiện tính độc đáo của dự án của mình nhé!",
    href: "/app",
    categories: [
      "Công nghệ",
      "Nghệ thuật",
      "Trò chơi",
      "Thể thao",
      "Thương mại",
      "Dịch vụ",
      "Du lịch",
      "Giáo dục",
      "Nông nghiệp",
    ],
    cards: [
      {
        image: Asifarm,
        label: "Farm",
        text: "AsiFarm",
        time: "01/01/2024",
        href: "https://asifarm.vercel.app/",
      },
      {
        image: DurationWorld,
        label: "Sale",
        text: "Duration World",
        time: "01/01/2024",
        href: "https://durian-world.vercel.app/",
      },
      {
        image: Asisell,
        label: "Sale",
        text: "Asisell",
        time: "01/01/2024",
        href: "https://asisell.vercel.app/",
      },
      {
        image: Thinkerum,
        label: "Blog",
        text: "Thinkerum",
        time: "01/01/2024",
        href: "https://thinkerum.vercel.app/",
      },
      {
        image: Asisell,
        label: "Admin",
        text: "Admin Asisell",
        time: "01/01/2024",
        href: "https://admin-asisell.vercel.app/",
      },
    ],
  },
  {
    title: "Các dự án Marketing",
    text: "Mọi nỗ lực của chúng ta nhằm mục tiêu là “Bán được hàng” và “Nhiều người biết tới”. Đừng lo lắng, ViNi cũng sẽ hỗ trợ Qúy khách hàng các vấn đề liên quan đến Marketing và Xây dựng Thương hiệu một cách bài bản nhất!",
    href: "/marketing",
    categories: [
      "Công nghệ",
      "Nghệ thuật",
      "Trò chơi",
      "Thể thao",
      "Thương mại",
      "Dịch vụ",
      "Du lịch",
      "Giáo dục",
      "Nông nghiệp",
    ],
    cards: [
      {
        image: Asifarm,
        label: "Farm",
        text: "AsiFarm",
        time: "01/01/2024",
        href: "https://asifarm.vercel.app/",
      },
      {
        image: DurationWorld,
        label: "Sale",
        text: "Duration World",
        time: "01/01/2024",
        href: "https://durian-world.vercel.app/",
      },
      {
        image: Asisell,
        label: "Sale",
        text: "Asisell",
        time: "01/01/2024",
        href: "https://asisell.vercel.app/",
      },
      {
        image: Thinkerum,
        label: "Blog",
        text: "Thinkerum",
        time: "01/01/2024",
        href: "https://thinkerum.vercel.app/",
      },
      {
        image: Asisell,
        label: "Admin",
        text: "Admin Asisell",
        time: "01/01/2024",
        href: "https://admin-asisell.vercel.app/",
      },
    ],
  },
];

export { infoNumbers, projectsList };
