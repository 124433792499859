export default function CardIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 43 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.2604 5.01074H11.7396C8.97705 5.01074 7.59578 5.01074 6.54934 5.56777C5.71512 6.01182 5.03232 6.69461 4.58827 7.52883C4.03125 8.57528 4.03125 9.95654 4.03125 12.7191H38.9688C38.9688 9.95654 38.9688 8.57528 38.4117 7.52883C37.9677 6.69461 37.2849 6.01182 36.4507 5.56777C35.4042 5.01074 34.0229 5.01074 31.2604 5.01074Z"
        fill="#11979F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9688 15.0312H4.03125V23.4396C4.03125 27.0018 4.03125 28.783 4.93842 30.0316C5.2314 30.4348 5.58602 30.7894 5.98927 31.0824C7.23788 31.9896 9.019 31.9896 12.5813 31.9896H30.4187C33.981 31.9896 35.7621 31.9896 37.0107 31.0824C37.414 30.7894 37.7686 30.4348 38.0616 30.0316C38.9688 28.783 38.9688 27.0018 38.9688 23.4396V15.0312ZM33.5938 18.5C33.5938 19.1386 32.9921 19.6562 32.25 19.6562C31.5079 19.6562 30.9062 19.1386 30.9062 18.5C30.9062 17.8614 31.5079 17.3438 32.25 17.3438C32.9921 17.3438 33.5938 17.8614 33.5938 18.5ZM24.1875 17.75C23.7733 17.75 23.4375 18.0858 23.4375 18.5C23.4375 18.9142 23.7733 19.25 24.1875 19.25H27.7708C28.185 19.25 28.5208 18.9142 28.5208 18.5C28.5208 18.0858 28.185 17.75 27.7708 17.75H24.1875Z"
        fill="#11979F"
      />
    </svg>
  );
}
