export default function LocationIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1711 25.5644C18.9675 26.7626 17.8064 27.7447 16.9466 28.4272C16.6803 28.6386 16.5471 28.7443 16.3667 28.8476C15.5846 29.2955 14.4157 29.2955 13.6336 28.8476C13.4532 28.7443 13.3201 28.6386 13.0538 28.4273L13.0538 28.4272L13.0538 28.4272C12.194 27.7447 11.0329 26.7626 9.8293 25.5644C7.46264 23.2082 4.76746 19.8574 4.08086 16.1961C2.69202 8.79013 8.31639 3 15.0002 3C21.684 3 27.3079 8.79017 25.9195 16.1961C25.233 19.8574 22.5378 23.2082 20.1711 25.5644ZM15 19.5334C17.4162 19.5334 19.375 17.444 19.375 14.8667C19.375 12.2894 17.4162 10.2 15 10.2C12.5838 10.2 10.625 12.2894 10.625 14.8667C10.625 17.444 12.5838 19.5334 15 19.5334Z"
        fill="url(#paint0_linear_16_2764)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16_2764"
          x1="15.0001"
          y1="3"
          x2="15.0001"
          y2="29.1835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0792BE" />
          <stop offset="1" stopColor="#20D3FB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
