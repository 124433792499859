export const routesPath = [
  "/",
  "/introduction",
  "/cost-and-policy",
  "/activity",
  "/blog",
  "/contact",
  "/web",
  "/app",
  "/marketing",
];
