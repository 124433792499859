import clsx from "clsx";
import IconButton from "../iconButton/iconButton";

import "./modal.css";

function Modal({
  children,
  title,
  setIsShow,
  className,
  contentContainerStyles,
  contentStyles,
}) {
  return (
    <div className={clsx("modal modal-opacity", className)}>
      <div
        className={clsx(
          "modal-content-container modal-fade-in",
          contentContainerStyles,
        )}
      >
        {title && (
          <div className="modal-header">
            <span className="modal-title">{title}</span>
            <IconButton className="modal-close-btn" onClick={setIsShow}>
              <i className="fa-regular fa-circle-xmark"></i>
            </IconButton>
          </div>
        )}
        <div className={clsx("modal-content", contentStyles)}>{children}</div>
      </div>
    </div>
  );
}

export default Modal;
