import { useEffect } from "react";
import clsx from "clsx";
import Image from "../image/image";
import Logo from "~/assets/images/logo.png";

import "./loading.css";

function Loading({ isMounted = false, className }) {
  useEffect(() => {
    const body = document.body;

    isMounted
      ? (body.style.overflow = "hidden")
      : body.removeAttribute("style");
  }, [isMounted]);

  return (
    <div className={clsx("loading", className)}>
      <div className="loading-img-container">
        <Image className="loading-img" src={Logo} alt="ViniPR Logo" />
      </div>
    </div>
  );
}

export default Loading;
