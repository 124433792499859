import React from "react";
import { routesPath } from "~/constants/routesPath";

// Main Pages
import HomePage from "~/pages/home/home";

// Project
const WebProject = React.lazy(() => import("~/pages/project/web/web"));
const AppProject = React.lazy(() => import("~/pages/project/app/app"));
const MarketingProject = React.lazy(
  () => import("~/pages/project/marketing/marketing"),
);

// Introduction
const Introduction = React.lazy(
  () => import("~/pages/introduction/introduction"),
);

// Contact
const Contact = React.lazy(() => import("~/pages/contact/contact"));

// Activity
const Activity = React.lazy(() => import("~/pages/activity/activity"));

// Blog
const Blog = React.lazy(() => import("~/pages/blog/blog"));

// Cost And Policy
const CostAndPolicy = React.lazy(
  () => import("~/pages/costAndPolicy/costAndPolicy"),
);

const publicRoutes = [
  {
    path: routesPath[0],
    component: HomePage,
  },
  {
    path: routesPath[1],
    component: Introduction,
  },
  {
    path: routesPath[2],
    component: CostAndPolicy,
  },
  {
    path: routesPath[3],
    component: Activity,
  },
  {
    path: routesPath[4],
    component: Blog,
  },
  {
    path: routesPath[5],
    component: Contact,
  },
  {
    path: routesPath[6],
    component: WebProject,
  },
  {
    path: routesPath[7],
    component: AppProject,
  },
  {
    path: routesPath[8],
    component: MarketingProject,
  },
];

const protectedRoutes = [];

export { publicRoutes, protectedRoutes };
