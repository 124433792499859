import { useState } from "react";
import clsx from "clsx";
import { Drawer } from "antd";
import Image from "../image/image";
import Button from "../button/button";
import NavBar from "../navBar/navBar";
import SmallOwl from "~/assets/images/small-owl.png";

import "./mobileMenu.css";

function MobileMenu({ isCostAndPolicy, className, btnStyles }) {
  const [isShow, setIsShow] = useState(false);

  const showDrawer = () => {
    setIsShow(true);
  };

  const onClose = () => {
    setIsShow(false);
  };

  return (
    <div className={clsx("mobile-menu", className)}>
      <Button
        className={clsx("mobile-menu-btn", btnStyles)}
        onClick={showDrawer}
      >
        <i className="fa-solid fa-bars"></i>
      </Button>
      <Drawer
        className="mobile-menu-content"
        placement="right"
        width={300}
        onClose={onClose}
        open={isShow}
      >
        <NavBar
          className="mobile-menu-nav-bar"
          isCostAndPolicy={isCostAndPolicy}
        />
        <div className="mobile-menu-phone">
          <Button
            className="general-btn mobile-menu-phone-btn"
            textBtn="0707 87 26 38"
            href="tel:0707872638"
          />
          <Image
            className="mobile-menu-phone-img"
            src={SmallOwl}
            alt="Small Owl"
          />
        </div>
      </Drawer>
    </div>
  );
}

export default MobileMenu;
