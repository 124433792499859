export default function StarIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 44 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6002 8.01606C18.6241 3.13737 19.636 0.69803 21.2454 0.311825C21.7439 0.192202 22.2636 0.192202 22.7621 0.311825C24.3715 0.69803 25.3834 3.13737 27.4073 8.01606L28.4423 10.5111C28.8072 11.3909 28.9897 11.8307 29.3153 12.1296C29.4667 12.2685 29.6386 12.3833 29.8249 12.4699C30.2257 12.6562 30.702 12.6562 31.6544 12.6562H33.5484C38.9053 12.6562 41.5838 12.6562 42.5199 13.5285C43.4142 14.3618 43.7652 15.6265 43.4284 16.8016C43.0759 18.0316 40.7807 19.4122 36.1903 22.1734C35.3844 22.6582 34.9814 22.9005 34.7399 23.2396C34.5055 23.5688 34.3763 23.9614 34.3693 24.3655C34.3622 24.7817 34.5423 25.216 34.9027 26.0847L34.9881 26.2906C37.0885 31.3539 38.1387 33.8855 37.4313 35.234C37.0605 35.9409 36.4425 36.4866 35.6951 36.7671C34.2693 37.3021 31.8872 35.9465 27.1229 33.2353L23.7843 31.3353C23.0134 30.8967 22.628 30.6773 22.2103 30.6339C22.073 30.6197 21.9345 30.6197 21.7972 30.6339C21.3795 30.6773 20.9941 30.8967 20.2232 31.3353L16.9126 33.2193C12.1248 35.9439 9.73089 37.3062 8.29962 36.7622C7.56225 36.482 6.95197 35.9431 6.58259 35.2462C5.86558 33.8933 6.92098 31.3491 9.03177 26.2608L9.11611 26.0575C9.47426 25.1941 9.65334 24.7624 9.64678 24.3476C9.6405 23.9504 9.51606 23.564 9.28933 23.2378C9.05258 22.8972 8.65523 22.6512 7.86052 22.1591C3.37716 19.3832 1.13548 17.9952 0.799228 16.7595C0.482582 15.5958 0.836335 14.3525 1.71814 13.5298C2.65455 12.6562 5.29113 12.6562 10.5643 12.6562H12.3553C13.3056 12.6562 13.7807 12.6562 14.1808 12.4707C14.3684 12.3838 14.5414 12.2683 14.6936 12.1283C15.0182 11.8298 15.2002 11.3909 15.5644 10.5131L16.6002 8.01606Z"
        fill="#11979F"
      />
    </svg>
  );
}
