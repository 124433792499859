import { useState } from "react";
import clsx from "clsx";
import Image from "../image/image";
import Button from "../button/button";
import Modal from "../modal/modal";
import TransparentButton from "../transparentButton/transparentButton";
import SparklingRectangle from "~/assets/images/sparkling-rectangle.png";
import { showModal } from "~/utils/common";

import "./headerContent.css";

function HeaderContent({
  texts = [],
  gradientText,
  subtext,
  infoNumbers = [],
  image,
  className,
  infoNumberStyles,
}) {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <div className={clsx("header-content", className)}>
        <div className="header-content-side" data-aos="zoom-in">
          <div className="header-content-title">
            {texts[0]}{" "}
            <span className="header-content-gradient-title">
              {gradientText}
            </span>{" "}
            {texts[1]}
          </div>
          <span className="header-content-text">{subtext}</span>
          <div className="header-content-btn-container">
            <Button
              className="general-btn header-content-btn"
              textBtn="Ưu đãi mới nhất"
              onClick={() => showModal(isShowModal, setIsShowModal)}
            />
            <TransparentButton
              textBtn="Bảng giá và Chính sách"
              to="/cost-and-policy"
            />
          </div>
        </div>
        <div
          className="header-content-side header-content-right-side"
          data-aos="fade-left"
        >
          <Image className="header-content-image" src={image} />
          <div className={clsx("header-content-info-number", infoNumberStyles)}>
            <Image src={SparklingRectangle} alt="Sparkling Rectangle" />
            <ul className="general-list header-content-list">
              {infoNumbers.map((info, index) => (
                <li className="header-content-item" key={index}>
                  <span className="header-content-number">{info.number}</span>
                  <span className="header-content-label">{info.label}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {isShowModal && (
        <Modal
          title="Ưu đãi"
          setIsShow={() => showModal(isShowModal, setIsShowModal)}
        >
          <div className="pb-4 text-center text-3xl font-semibold">
            Ưu đãi đang được cập nhật
          </div>
        </Modal>
      )}
    </>
  );
}

export default HeaderContent;
