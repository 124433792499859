import { useState } from "react";
import Button from "~/components/button/button";
import { faqs } from "./index";

import "./faq.css";

function FAQs() {
  const [indexItem, setIndexItem] = useState();
  return (
    <div className="general-padding faq">
      <div className="faq-title-container" data-aos="zoom-in">
        <span className="faq-title">
          FAQs - Bạn <span className="text-orange">hỏi</span> và ViNi{" "}
          <span className="text-blue">đáp</span>!
        </span>
        <span className="faq-text">
          Những thắc mắc của Quý khách sẽ được chúng tôi giải đáp một cách tốt
          nhất. Chúng tôi hiểu rằng đây là sự yêu thương của Qúy khách với chúng
          tôi!
        </span>
      </div>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <Button
            className="faq-item"
            data-aos="zoom-in"
            key={index}
            onClick={() => setIndexItem(index)}
          >
            <div>
              {indexItem === index ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-plus"></i>
              )}
              <span>{faq.question}</span>
            </div>
            {indexItem === index && <span>{faq.answer}</span>}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
