import clsx from "clsx";
import Image from "~/components/image/image";
import Button from "~/components/button/button";
import ADongTextLogo from "~/assets/images/a-dong-text-logo.png";
import TextLogo from "~/assets/images/text-logo.png";
import { infos, socialNetworks } from "./index";

import "./partFour.css";

function PartFour({ className }) {
  return (
    <div
      className={clsx("footer-section general-padding part-four", className)}
    >
      <div>
        <div className="general-gradient-title">Vận hành bởi</div>
        <Image src={ADongTextLogo} alt="A Dong Text Logo" />
      </div>
      <div>
        <div className="part-four-title-container">
          <span className="general-gradient-title">Vinipr Agency</span>
          <Image src={TextLogo} alt="A Dong Text Logo" />
        </div>
        <ul className="general-list part-four-list">
          {infos.map((info, index) => {
            const Icon = info.icon;

            return (
              <li className="part-four-item" key={index}>
                <Icon />
                <div className="part-four-text-container">
                  <span className="part-four-title">
                    {info.title}{" "}
                    {info.image && (
                      <Image className="part-four-image" src={info.image} />
                    )}
                  </span>
                  <span>
                    {info.label}{" "}
                    <span className="text-dark-sky">{info.text}</span>
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <ul className="general-list part-four-icon-container">
          {socialNetworks.map((social, index) => {
            const Icon = social.icon;

            return (
              <li key={index}>
                <Button href={social.href}>
                  <Icon className="part-four-icon" />
                </Button>
              </li>
            );
          })}
        </ul>
        <div className="part-four-copyright-container">
          <div className="part-four-term">
            <Button to="#" textBtn="Privacy Policy"></Button>
            <span className="part-four-separator">|</span>
            <Button to="#" textBtn="Terms Of Service"></Button>
          </div>
          <div className="part-four-copyright">
            <span>All Rights Reserved</span>
            <span>@2021 Vinipr Agency</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartFour;
