export default function GradientInstagramIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 40 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_17_2843)">
        <path
          d="M30.8333 10.8337C30.8333 11.7541 30.0871 12.5003 29.1667 12.5003C28.2462 12.5003 27.5 11.7541 27.5 10.8337C27.5 9.91318 28.2462 9.16699 29.1667 9.16699C30.0871 9.16699 30.8333 9.91318 30.8333 10.8337Z"
          fill="url(#paint0_linear_17_2843)"
        />
        <path
          d="M5 12.2C5 9.20021 5 7.70032 5.76393 6.64886C6.01065 6.30928 6.30928 6.01065 6.64886 5.76393C7.70032 5 9.20021 5 12.2 5H27.8C30.7998 5 32.2997 5 33.3511 5.76393C33.6907 6.01065 33.9893 6.30928 34.2361 6.64886C35 7.70032 35 9.20021 35 12.2V27.8C35 30.7998 35 32.2997 34.2361 33.3511C33.9893 33.6907 33.6907 33.9893 33.3511 34.2361C32.2997 35 30.7998 35 27.8 35H12.2C9.20021 35 7.70032 35 6.64886 34.2361C6.30928 33.9893 6.01065 33.6907 5.76393 33.3511C5 32.2997 5 30.7998 5 27.8V12.2Z"
          stroke="url(#paint1_linear_17_2843)"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M26.6667 20C26.6667 23.6819 23.6819 26.6667 20 26.6667C16.3181 26.6667 13.3333 23.6819 13.3333 20C13.3333 16.3181 16.3181 13.3333 20 13.3333C23.6819 13.3333 26.6667 16.3181 26.6667 20Z"
          stroke="url(#paint2_linear_17_2843)"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_17_2843"
          x="-4"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_2843"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_2843"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_17_2843"
          x1="29.1667"
          y1="9.16699"
          x2="29.1667"
          y2="12.5003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#968DFD" />
          <stop offset="1" stopColor="#DCB9EC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17_2843"
          x1="20"
          y1="5"
          x2="20"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#968DFD" />
          <stop offset="1" stopColor="#DCB9EC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17_2843"
          x1="20"
          y1="5"
          x2="20"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#968DFD" />
          <stop offset="1" stopColor="#DCB9EC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
