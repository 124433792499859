import clsx from "clsx";
import IconButton from "../iconButton/iconButton";

import "./transparentButton.css";

function TransparentButton({
  to,
  href,
  textBtn,
  onClick,
  isDisabled = false,
  isOpenOtherTab = false,
  className,
}) {
  return (
    <IconButton
      className={clsx("transparent-btn", className)}
      to={to}
      href={href}
      onClick={onClick}
      isDisabled={isDisabled}
      isOpenOtherTab={isOpenOtherTab}
    >
      <span className="transparent-text-btn">{textBtn}</span>
    </IconButton>
  );
}

export default TransparentButton;
