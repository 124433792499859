import { forwardRef } from "react";
import clsx from "clsx";
import Image from "../image/image";

import "./input.css";

const Input = forwardRef(
  (
    {
      srcLeftIcon,
      srcRightIcon,
      type = "text",
      placeholder,
      value,
      required = false,
      onClickRightIcon,
      children,
      rightChildren,
      isDisabled,
      onChange = () => {},
      aos,
      className,
      inputStyles,
      iconStyles,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <div className={clsx("input-container", className)} data-aos={aos}>
        {srcLeftIcon ? (
          <div className="input-icon-container left-icon-container">
            <Image
              src={srcLeftIcon}
              className={clsx("input-icon", iconStyles)}
            />
          </div>
        ) : (
          children
        )}
        <input
          className={clsx(
            "input-component",
            inputStyles,
            required ? "input-error-border" : "",
          )}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          ref={ref}
          disabled={isDisabled}
          {...inputProps}
        />
        {srcRightIcon ? (
          <div
            className="input-icon-container right-icon-container"
            onClick={onClickRightIcon}
          >
            <Image
              src={srcRightIcon}
              className={clsx("input-icon right-icon", iconStyles)}
            />
          </div>
        ) : (
          rightChildren
        )}
      </div>
    );
  },
);

export default Input;
