import { useState, useEffect } from "react";
import clsx from "clsx";
import IconButton from "../iconButton/iconButton";

import "./scrollToTopButton.css";

function ScrollToTopButton() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = window.scrollY;

    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  return (
    <div
      className={clsx(
        "scroll-to-top-button",
        !visible ? "hide-scroll-to-top-button" : null,
      )}
    >
      <IconButton
        className={clsx(
          "scroll-to-top-button-btn",
          !visible ? "hide-btn" : null,
        )}
        onClick={scrollToTop}
      >
        <i className="fa-solid fa-circle-up"></i>
      </IconButton>
    </div>
  );
}

export default ScrollToTopButton;
