import StarIcon from "~/assets/icons/starIcon";
import CardIcon from "~/assets/icons/cardIcon";
import ACBBank from "~/assets/images/acb-bank.png";
import GradientInstagramIcon from "~/assets/icons/gradientInstagramIcon";
import GradientTwitterIcon from "~/assets/icons/gradientTwitterIcon";
import GradientLinkedInIcon from "~/assets/icons/GradientLinkedInIcon";
import GradientFacebookIcon from "~/assets/icons/gradientFacebookIcon";

const infos = [
  {
    title: "Công ty TNHH Vinipr Media",
    label: "MST:",
    text: "0317020213",
    icon: StarIcon,
  },
  {
    title: "Ngân hàng",
    label: "STK:",
    text: "1011202166",
    icon: CardIcon,
    image: ACBBank,
  },
];

const socialNetworks = [
  {
    icon: GradientInstagramIcon,
    href: "https://www.instagram.com/",
  },
  {
    icon: GradientTwitterIcon,
    href: "https://www.twitter.com/",
  },
  {
    icon: GradientLinkedInIcon,
    href: "https://www.linkedin.com/",
  },
  {
    icon: GradientFacebookIcon,
    href: "https://www.facebook.com/",
  },
];

export { infos, socialNetworks };
