import Input from "~/components/input/input";
import Button from "~/components/button/button";

import "./subscribe.css";

function Subscribe() {
  return (
    <div className="general-padding subscribe">
      <div className="subscribe-title-container" data-aos="zoom-in">
        <span className="subscribe-title">
          ĐỂ KHÔNG BỎ LỠ NHỮNG ĐIỀU TUYỆT VỜI
        </span>
        <span className="subscribe-text">
          Để lại Email để Vinipr có thể tìm thấy bạn và hỗ trợ bạn nhanh nhất có
          thể nhé!
        </span>
      </div>
      <div className="subscribe-input-container">
        <Input
          className="subscribe-input"
          placeholder="Nhập email của bạn"
          aos="fade-right"
        />
        <Button
          className="general-btn subscribe-btn"
          textBtn="Đăng ký"
          data-aos="fade-left"
        />
      </div>
    </div>
  );
}

export default Subscribe;
