import { useState, useEffect } from "react";
import clsx from "clsx";
import Image from "~/components/image/image";
import Button from "~/components/button/button";
import CategorySection from "./categorySection/categorySection";
import BigOwl from "~/assets/images/big-owl.png";
import { categories, infos } from "./index";

import "./partThree.css";

function PartThree({ className }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const newCategories = categories.filter(
      (category, index) => index < 4 && category,
    );

    setItems(newCategories);
  }, []);

  return (
    <div
      className={clsx("footer-section general-padding part-three", className)}
    >
      <div className="part-three-section-one">
        {items.map((item, index) => (
          <CategorySection
            className="footer-category"
            title={item.title}
            categories={item.items}
            key={index}
          />
        ))}
      </div>
      <div className="part-three-section-two">
        <div>
          <CategorySection
            className="footer-category"
            title={categories[4].title}
            categories={categories[4].items}
          />
          <div className="part-three-list">
            <span className="general-gradient-title footer-category-title">
              Liên hệ
            </span>
            <ul className="general-list">
              {infos.map((info, index) => {
                const Icon = info.icon;

                return (
                  <li className="part-three-item" key={index}>
                    <Icon />
                    <div className="part-three-text-container">
                      <span className="general-title">{info.title}</span>
                      {info.href ? (
                        <Button textBtn={info.text} href={info.href} />
                      ) : (
                        <span>{info.text}</span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <Image src={BigOwl} alt="Big Owl" />
      </div>
    </div>
  );
}

export default PartThree;
