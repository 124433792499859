import { useEffect } from "react";
import Button from "~/components/button/button";
import Image from "~/components/image/image";
import NotFoundImg from "~/assets/images/not-found.png";
import { setDocumentTitle } from "~/utils/common";

import "./notFound.css";

function NotFound() {
  useEffect(() => {
    setDocumentTitle("Không tìm thấy trang");
  }, []);

  return (
    <div className="not-found">
      <div className="general-body not-found-content-container">
        <Image className="not-found-img" src={NotFoundImg} alt="Not Found" />
        <div className="not-found-content">
          <span className="not-found-title">404 Whoops!</span>
          <span className="not-found-sub-title">không tìm thấy trang</span>
          <Button
            className="general-btn not-found-btn"
            to="/"
            textBtn="Về nhà thôi"
          />
        </div>
      </div>
    </div>
  );
}

export default NotFound;
