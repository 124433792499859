export default function GradientFacebookIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4667 35H21.5334C22.2834 35 22.6583 35 22.9212 34.809C23.0061 34.7473 23.0808 34.6727 23.1424 34.5878C23.3334 34.3249 23.3334 33.9499 23.3334 33.2V22.5H26.5334C27.2834 22.5 27.6583 22.5 27.9212 22.309C28.0061 22.2473 28.0808 22.1727 28.1424 22.0878C28.3334 21.8249 28.3334 21.4499 28.3334 20.7V18.4667C28.3334 17.7167 28.3334 17.3417 28.1424 17.0789C28.0808 16.994 28.0061 16.9193 27.9212 16.8576C27.6583 16.6667 27.2834 16.6667 26.5334 16.6667H23.3334V12.6333C23.3334 11.8834 23.3334 11.5084 23.5244 11.2455C23.5861 11.1607 23.6607 11.086 23.7456 11.0243C24.0085 10.8333 24.3835 10.8333 25.1334 10.8333H26.5334C27.2834 10.8333 27.6583 10.8333 27.9212 10.6424C28.0061 10.5807 28.0808 10.506 28.1424 10.4211C28.3334 10.1583 28.3334 9.78328 28.3334 9.03333V6.8C28.3334 6.05005 28.3334 5.67508 28.1424 5.41221C28.0808 5.32732 28.0061 5.25266 27.9212 5.19098C27.6583 5 27.2834 5 26.5334 5H22.9667C20.3419 5 19.0295 5 18.1095 5.66844C17.8124 5.88432 17.5511 6.14562 17.3352 6.44275C16.6667 7.36278 16.6667 8.67519 16.6667 11.3V16.6667H13.4667C12.7168 16.6667 12.3418 16.6667 12.079 16.8576C11.9941 16.9193 11.9194 16.994 11.8577 17.0789C11.6667 17.3417 11.6667 17.7167 11.6667 18.4667V20.7C11.6667 21.4499 11.6667 21.8249 11.8577 22.0878C11.9194 22.1727 11.9941 22.2473 12.079 22.309C12.3418 22.5 12.7168 22.5 13.4667 22.5H16.6667V33.2C16.6667 33.9499 16.6667 34.3249 16.8577 34.5878C16.9194 34.6727 16.9941 34.7473 17.079 34.809C17.3418 35 17.7168 35 18.4667 35Z"
        stroke="url(#paint0_linear_17_2911)"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17_2911"
          x1="20.0001"
          y1="5"
          x2="20.0001"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6C2FA" />
          <stop offset="1" stopColor="#D490F3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
