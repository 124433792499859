export const faqs = [
  {
    question: "VINIPR có những dịch vụ gì?",
    answer: "",
  },
  {
    question: "Chính sách thanh toán chia nhỏ cụ thể là như thế nào?",
    answer: "",
  },
  {
    question: "Dịch vụ Marketing trọn gói là gì? Chi phí bao nhiêu?",
    answer: "",
  },
  {
    question: "Chính sách bảo hành như thế nào?",
    answer: "",
  },
  {
    question:
      "Dịch vụ thiết kế và gia công Website/APP có chi phí như thế nào?",
    answer: "",
  },
  {
    question: "Có chính sách ưu đãi gì đang diễn ra hay không?",
    answer:
      "Hiện tại, chúng tôi đang diễn ra chương trình “Đồng hành cùng nhau”, chương trình chỉ áp dụng khi quý khách sử dụng dịch vụ Thiết kế và gia công Website thôi nhé!",
  },
];
