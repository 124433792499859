import { useState, useEffect } from "react";
import clsx from "clsx";
import Button from "../button/button";
import { navBar, costAndPolicy } from "./index";

import "./navBar.css";

function NavBar({ isCostAndPolicy, setTitle, navBarTitle, className }) {
  const [navTitle, setNavTitle] = useState(navBarTitle);
  const [costTitle, setCostTitle] = useState(costAndPolicy[0].title);

  return (
    <div
      className={clsx(
        "general-list nav-bar",
        isCostAndPolicy && "cost-and-policy",
        className,
      )}
    >
      {!isCostAndPolicy
        ? navBar.map((nav, index) => (
            <Button
              className={clsx(
                "nav-item",
                navTitle === nav.title && "nav-item--activated",
              )}
              to={nav.href}
              onClick={() => setNavTitle(nav.title)}
              key={index}
            >
              {nav.title}
            </Button>
          ))
        : costAndPolicy.map((nav, index) => (
            <Button
              className={clsx(
                "nav-item",
                costTitle === nav.title && "nav-item--activated",
              )}
              key={index}
              onClick={() => {
                setTitle(nav.title);
                setCostTitle(nav.title);
              }}
            >
              {nav.title}
            </Button>
          ))}
    </div>
  );
}

export default NavBar;
