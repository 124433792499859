export default function GradientLinkedInIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 40 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_17_2891)">
        <path
          d="M18.3333 17.5V27.5V23.2292C18.3333 22.552 18.3333 22.2133 18.3661 21.9286C18.6317 19.6198 20.4531 17.7984 22.7619 17.5328C23.0467 17.5 23.3853 17.5 24.0625 17.5V17.5C24.4688 17.5 24.672 17.5 24.8429 17.5197C26.2281 17.679 27.321 18.7719 27.4803 20.1571C27.5 20.328 27.5 20.5312 27.5 20.9375V27.5M12.5 17.5V27.5"
          stroke="url(#paint0_linear_17_2891)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="12.4999"
          cy="12.4997"
          r="1.66667"
          fill="url(#paint1_linear_17_2891)"
        />
        <path
          d="M5 12.2C5 9.20021 5 7.70032 5.76393 6.64886C6.01065 6.30928 6.30928 6.01065 6.64886 5.76393C7.70032 5 9.20021 5 12.2 5H27.8C30.7998 5 32.2997 5 33.3511 5.76393C33.6907 6.01065 33.9893 6.30928 34.2361 6.64886C35 7.70032 35 9.20021 35 12.2V27.8C35 30.7998 35 32.2997 34.2361 33.3511C33.9893 33.6907 33.6907 33.9893 33.3511 34.2361C32.2997 35 30.7998 35 27.8 35H12.2C9.20021 35 7.70032 35 6.64886 34.2361C6.30928 33.9893 6.01065 33.6907 5.76393 33.3511C5 32.2997 5 30.7998 5 27.8V12.2Z"
          stroke="url(#paint2_linear_17_2891)"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_17_2891"
          x="-4"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_2891"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_2891"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_17_2891"
          x1="20"
          y1="17.5"
          x2="20"
          y2="27.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFBBEF" />
          <stop offset="1" stopColor="#E696F3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17_2891"
          x1="12.4999"
          y1="10.833"
          x2="12.4999"
          y2="14.1663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFBBEF" />
          <stop offset="1" stopColor="#E696F3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17_2891"
          x1="20"
          y1="5"
          x2="20"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFBBEF" />
          <stop offset="1" stopColor="#E696F3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
