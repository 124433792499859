import clsx from "clsx";
import Header from "~/components/header/header";
import Footer from "~/components/footer/footer";
import ScrollToTopButton from "~/components/scrollToTopButton/scrollToTopButton";

function Page({ setTitle, isCostAndPolicy, navBarTitle, children, className }) {
  return (
    <div className={clsx("general-container", className)}>
      <Header
        isCostAndPolicy={isCostAndPolicy}
        setTitle={setTitle}
        navBarTitle={navBarTitle}
      />
      {children}
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

export default Page;
