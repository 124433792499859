export default function MailIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.20077 9.89453C2.90625 10.9987 2.90625 12.4333 2.90625 15.0003C2.90625 17.9098 2.90625 19.3645 3.33508 20.5322C4.08349 22.5701 5.74035 24.1735 7.84615 24.8978C9.05278 25.3128 10.556 25.3128 13.5625 25.3128H17.4375C20.444 25.3128 21.9472 25.3128 23.1538 24.8978C25.2597 24.1735 26.9165 22.5701 27.6649 20.5322C28.0938 19.3645 28.0938 17.9098 28.0938 15.0003C28.0938 12.4333 28.0938 10.9987 27.7992 9.89456L22.9323 13.3652C21.4936 14.3911 20.3598 15.1997 19.3776 15.7725C18.3712 16.3595 17.4562 16.741 16.4413 16.8664C15.8167 16.9436 15.1844 16.9436 14.5598 16.8665C13.5449 16.7411 12.6299 16.3596 11.6235 15.7727C10.6412 15.1999 9.5074 14.3914 8.06867 13.3655L3.20077 9.89453Z"
        fill="url(#paint0_linear_16_2762)"
      />
      <path
        d="M3.99365 8.1306L9.17918 11.8281C10.6652 12.8877 11.7273 13.6438 12.6235 14.1665C13.5045 14.6802 14.1569 14.9262 14.8052 15.0063C15.2669 15.0633 15.7342 15.0633 16.1959 15.0063C16.8442 14.9261 17.4966 14.6801 18.3775 14.1664C19.2737 13.6436 20.3358 12.8875 21.8217 11.8278L27.0064 8.13061C26.127 6.7362 24.7731 5.65943 23.1539 5.1025C21.9472 4.6875 20.444 4.6875 17.4375 4.6875L13.5625 4.6875C10.556 4.6875 9.05279 4.6875 7.84617 5.1025C6.22689 5.65943 4.87308 6.73619 3.99365 8.1306Z"
        fill="url(#paint1_linear_16_2762)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16_2762"
          x1="15.5"
          y1="9.89453"
          x2="15.5"
          y2="25.3128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B8DA9" />
          <stop offset="1" stopColor="#1BCCF3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_2762"
          x1="15.5"
          y1="4.6875"
          x2="15.5"
          y2="15.0491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B8DA9" />
          <stop offset="1" stopColor="#1BCCF3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
