import clsx from "clsx";
import PartOne from "./components/partOne/partOne";
import PartTwo from "./components/partTwo/partTwo";
import PartThree from "./components/partThree/partThree";
import PartFour from "./components/partFour/partFour";

import "./footer.css";

function Footer({ className }) {
  return (
    <div className={clsx("footer", className)}>
      <PartOne />
      <PartTwo />
      <PartThree />
      <PartFour />
    </div>
  );
}

export default Footer;
