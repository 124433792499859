import clsx from "clsx";
import BriefCard from "~/components/card/briefCard/briefCard";
import { setAosDuration } from "~/utils/common";

import "./cardSection.css";

function CardSection(cards = [], className) {
  return (
    <ul className={clsx("general-list card-section", className)}>
      {cards.cards.map((card, index) => (
        <BriefCard
          data-aos="fade-right"
          data-aos-duration={setAosDuration(index)}
          image={card.image}
          label={card.label}
          time={card.time}
          text={card.text}
          href={card.href}
          key={index}
        />
      ))}
    </ul>
  );
}

export default CardSection;
