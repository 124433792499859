import { useState } from "react";
import clsx from "clsx";

import "./category.css";

function Category({ categories = [], className }) {
  const [indexItem, setIndexItem] = useState(0);

  return (
    <ul className={clsx("general-list category", className)}>
      {categories.map((category, index) => (
        <li
          className={clsx(
            "category-item",
            indexItem === index && "category-activated-item",
          )}
          key={index}
          onClick={() => setIndexItem(index)}
        >
          <span>{category}</span>
        </li>
      ))}
    </ul>
  );
}

export default Category;
