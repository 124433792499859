export default function GradientTwitterIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_17_2862)">
        <path
          d="M6.11371 11.2412C6.11371 10.0972 6.4013 9.01817 6.89262 8.09516C9.47853 11.537 13.217 13.9101 17.474 14.5168C17.458 14.3249 17.4499 14.1307 17.4499 13.9347C17.4499 10.0778 20.6005 6.95114 24.4871 6.95114C25.6915 6.95114 26.8252 7.2514 27.8164 7.78059C28.1448 7.95591 28.4575 8.15635 28.752 8.37933L28.752 8.37935C29.1524 8.68252 29.3526 8.83411 29.5278 8.86629C29.5925 8.87817 29.6363 8.88102 29.702 8.87763C29.8799 8.86844 30.0655 8.76263 30.4368 8.55101L33.79 6.63968C34.809 6.05886 35.3185 5.76846 35.5673 5.91297C35.643 5.95694 35.7058 6.02 35.7494 6.09585C35.893 6.34519 35.6006 6.85356 35.0159 7.87028L31.9041 13.2807L31.9041 13.2807C31.838 13.3957 31.805 13.4531 31.7881 13.5149C31.7824 13.5358 31.7782 13.5558 31.7752 13.5772C31.7663 13.6406 31.7743 13.7095 31.7902 13.8473C33.1885 25.9573 24.9023 33.3333 15.8861 33.3333C11.6544 33.3333 7.81535 31.6654 5 28.9558C5.43517 29.0147 5.87082 29.0384 6.31742 29.0384C9.02564 29.0384 11.5182 28.0374 13.4954 26.3604C10.9669 26.3084 8.83391 24.5014 8.10293 22.0053C8.46243 22.0833 8.82193 22.1223 9.19341 22.1223C9.72067 22.1223 10.236 22.0443 10.7153 21.9013C8.06698 21.3163 6.07776 18.7943 6.07776 15.7522V15.6742C6.85667 16.1423 7.74343 16.4283 8.69011 16.4543C7.13228 15.3362 6.11371 13.4122 6.11371 11.2412Z"
          stroke="url(#paint0_linear_17_2862)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_17_2862"
          x="-4"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_2862"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_2862"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_17_2862"
          x1="20.8333"
          y1="5"
          x2="20.8333"
          y2="33.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9991EF" />
          <stop offset="1" stopColor="#E06BF3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
