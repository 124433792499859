// Number formatting
const formatNumber = (num) => {
  const regex = /(\d)(?=(\d{3})+(?!\d))/g;

  const number = num ? num : 0;

  return number.toString().replace(regex, "$1,");
};

// Format Number Less Than 10
const formatNumberLessThanTen = (number) => {
  return number ? (number < 10 ? "0" + number : number) : 0;
};

// White Space
const hasWhiteSpace = (s) => {
  return /\s/g.test(s);
};

// Set Document Title
const setDocumentTitle = (title) => {
  return (document.title = `${title} | ViniPR`);
};

// Is Empty String
function isEmptyString(string) {
  return string || "";
}

// Is Empty Object
function isEmptyObject(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

// Convert Currency
const convertCurrency = (currency) => {
  const currencyUnit = "đ";

  return `${formatNumber(currency)} ${currencyUnit}`;
};

// Show Modal
const showModal = (isShow, setIsShow) =>
  isShow ? setIsShow(false) : setIsShow(true);

// Set Aos Duration
const setAosDuration = (index) => (200 * (index + 1)).toString();

export {
  formatNumber,
  formatNumberLessThanTen,
  hasWhiteSpace,
  setDocumentTitle,
  isEmptyString,
  isEmptyObject,
  convertCurrency,
  showModal,
  setAosDuration,
};
